import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Bewerbungsprozess",
  "description": "Allgemeine Fragen zum Bewerbungsprozess",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`Werden die Eingangsbestätigungen für eine Bewerbung automatisch aus dem Programm versendet ohne dass jemand das manuell anschubsen muss?`}</em>{`
Eine Empfangsbestätigung wird automatisch nach Absenden der Bewerbungsunterlagen im Portal per E-Mail versandt. Die Textvorlage kann im Portal angepasst werden.`}</p>
    <p><em parentName="p">{`Kann man einem Anwender (z.B. einem Fachvorgesetzten) die Ansichtsrechte für einen Pool vergeben? Oder muss immer der Link für den einzelnen Bewerber verschickt werden, damit der Vorgesetzte sich für den Bewerber einloggen kann?`}</em>{`
Ja, es ist möglich die Berechtigungen auf Poolebene zu setzen, so dass ein Benutzer ihm zugeordnete Bewerber sehen kann.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      